<template>
<div id="PostWelcome" v-if="PostData != undefined || PostData != null">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<v-container>
<div>

    <v-row align="center" justify="center">
    <v-col cols="12" sm="12" md="6" class="my-5">
        <v-img :src="PostData.imageUrl" height="400px" alt="Event Image" class="elevation-10 white animated fadeIn slow">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
    </v-col>
    </v-row>

    <div class="mt-5">
        <p class="subtitle-1">Published : {{PostData.DOE}}</p>
        <p class="headline py-5 my-5">{{PostData.title}}</p>
    </div>

    <p class="my-5 subtitle-1" v-html="PostData.description"></p>

    <div class="py-5 display-1">
        Comments <hr class="mt-5">
    </div>

    <v-card class="my-5 pa-3 elevation-5">
        <v-card-title class="headline">Add Comment</v-card-title>
        <v-form @submit.prevent="AddComment()">
            <v-card-text>
                <v-text-field label="Name" v-model="name" required></v-text-field>
                <v-textarea label="Comment" v-model="comment" required></v-textarea>
            </v-card-text>
            <v-card-actions class="mb-5 d-flex justify-center align-center">
                <v-btn type="submit" :disabled="disbtn" large rounded class="primary px-5 elevation-10">Comment</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>

    <div v-if="CommentData || CommentDataSmall" >
      <p class="headline py-5" id="RecentComments">Recent Comments</p><hr class="mt-5">
    </div>

    <div class="py-3 animated fadeIn slower" v-if="CommentDataSmall && !loadMore">
        <v-container>
        <div v-for="comment in CommentDataSmall" :key="comment.addedAt.toString()">
            <v-lazy v-model="isActive" :options="{ threshold: .5 }" transition="fade-transition">
            <v-card class="my-5 pa-3 animated fadeIn slower">
                <v-card-title class="title elevation-0 pa-3 blue-grey lighten-5">{{comment.name}}</v-card-title>
                <div class="my-3 pa-0 blockquote ml-2" style="border-left: 5px solid #f00;border-radius: 0">
                    <v-card-text class="subtitle-1 text--primary">{{comment.comment}}
                    </v-card-text>
                </div>
            </v-card>
            </v-lazy>
        </div>
        </v-container>
    </div>

    <div class="py-5 animated fadeIn slower" v-if="CommentData && loadMore">
        <v-container>
        <div v-for="comment in CommentData" :key="comment.addedAt.toString()">
            <v-lazy v-model="isActive" :options="{ threshold: .5 }" transition="fade-transition">
            <v-card class="my-5 pa-3 animated fadeIn slower">
                <v-card-title class="title elevation-0 pa-3 blue-grey lighten-5">{{comment.name}}</v-card-title>
                <div class="my-3 pa-0 blockquote ml-2" style="border-left: 5px solid #f00;border-radius: 0">
                    <v-card-text class="subtitle-1 text--primary">{{comment.comment}}
                    </v-card-text>
                </div>
            </v-card>
            </v-lazy>
        </div>
        </v-container>
    </div>

    <div class="mb-5 d-flex align-center justify-center">
        <v-btn rounded v-if="CommentDataSmall.length < CommentData.length" @click="LoadMoreFun()" class="primary">
            <span v-if="!loadMore">Show More</span>
            <span v-if="loadMore">Show Less</span>
            <v-icon class="ml-3">mdi-sync mdi-18px</v-icon>
        </v-btn>
    </div>

    <!-- more articles -->
    <div class="mt-5 pt-5" v-show="PostDataSmall">

        <p class="title mb-2">More Articles</p><hr class="mb-5" width="250px">

        <v-row height="100%" class="mt-5">
        <v-col cols="12" sm="12" md="4" class="my-5" v-for="article in PostDataSmall" :key="article.slug" height="100%">
        <v-fade-transition>
        <v-card class="elevation-5 grey lighten-3 animated fadeIn slower" height="100%">

            <v-img height="200px" class="animated fadeIn slower" aspect-ratio="1" :src="article.imageUrl">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                </v-row>
            </template>
            </v-img>

            <div class="card-strip elevation-5">
            <v-card-subtitle class="py-3 subtitle"> Published : {{article.DOE}}</v-card-subtitle>
            </div>

            <v-card-text class="subtitle-1 card-outter">
            <p class="py-3">{{article.title}}</p>
            </v-card-text>

            <v-card-actions class="card-actions">
            <v-btn class="action-btn elevation-5" @click="$router.push('/articleoverview/' + article.slug)">
                Read<v-icon class="ml-2" dark>mdi-chevron-double-right</v-icon>
            </v-btn>
            </v-card-actions>

        </v-card>
        </v-fade-transition>
        </v-col>
        </v-row>

    </div>


</div>
</v-container>

</div>
</template>

<script>
// imports
import firebase from 'firebase/app';
import "firebase/firestore";

// scripts
export default {
    name: 'PostWelcome',

    data(){return{
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,
        name: '',comment: '',isActive: false,loadMore: false,
    }},

    computed: {

        PostSlug(){         
          return this.$route.params.slug != null ? this.$route.params.slug : null;
        },

        PostData(){
            if(this.PostSlug != null) {
                let data =  this.$store.getters.GetPostWithSlug(this.PostSlug);
                return data.length != 0 ? data[0] : null;
            }else{ return this.$router.push("/"); }             
        },

        PostDataSmall(){
            let data =  this.$store.getters.GetAllPosts.filter(post => post.slug != this.PostSlug).slice(0, 6);
            return data != '' ? data : null;
        },

        CommentData(){
            if(this.PostSlug != null){
                let data = this.$store.getters.GetCommentsWithSlug(this.PostSlug);
                return data.length != 0 ? data : false;
            }else{return false;}
        },

        CommentDataSmall(){
             if(this.PostSlug != null){
                let data = this.$store.getters.GetCommentsWithSlug(this.PostSlug);
                return data.length != 0 ? data.slice(0, 2) : false;
            }else{return false;}
        }
        
    },

    methods:{

        async AddComment(){
            if(!this.BlankValidation()){return false;}
            this.disbtn = true;
        
            await firebase.firestore().collection("comments").add({
                "slug": this.PostSlug,"name": this.name,"comment": this.comment,
                "addedAt": firebase.firestore.FieldValue.serverTimestamp()
            }).then((doc) => {
                    this.notificationService("Added Comment!", "success");
                    this.$store.dispatch('getComments');
                    this.name = '';this.comment = '';
            }).catch((err) => {console.log(err)});

            this.disbtn = false;
        },

        BlankValidation(){
            if(this.name == '', this.comment == ''){ 
                this.notificationService("Fill All Fields!", "red darken-1");return false;
            }else{return true; }
        },

        LoadMoreFun(){
            this.loadMore = !this.loadMore;
            this.$vuetify.goTo('#RecentComments', {duration: 1000});
        },

        // notification service
        notificationService(msg, type){
            this.notificationType = type;
            this.notificationMsg = msg;
            this.notificationToggle = true;
        },

        
    }
}
</script>

<style scoped>

#PostWelcome{
    padding-bottom: 3rem;
}
.card-strip{
  background-color: #1A3051 !important;
}
.card-strip .subtitle{
  color: #ddd !important;
}
.card-outter {
  position: relative;
  padding-bottom: 60px;
  color: #222 !important;
}
.card-actions {
  position: absolute;
  bottom: 0;
  padding: 20px 13px
}
.card-actions .action-btn {
  background-color: #1A3051 !important;
  border: 1px solid #ddd !important;
  color: #ddd !important;
}
</style>